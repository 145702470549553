import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import man2 from "../../img/manipulatormain2.jpeg";
import { Helmet } from "react-helmet";
function Manipulator2() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Полная масса</td>
            <td>12 тонн</td>
        </tr>
                    <tr>
            <td>Мощность двигателя	</td>
            <td>180 л.с.</td>
        </tr>
                    <tr>
            <td>КПП</td>
            <td>механическая</td>
        </tr>
                    <tr>
            <td>Экологический класс	</td>
            <td>ЕВРО 5</td>
        </tr>
                    <tr>
            <td>Масса в снаряенном состоянии</td>
            <td>7 тонн</td>
        </tr>
                    <tr>
            <td>Возможная масса груза</td>
            <td>5 тонн</td>
        </tr>
                    <tr>
            <td>Манипулятор</td>
            <td>MKG 142T</td>
        </tr>
                    <tr>
            <td>Грузовой момент</td>
            <td>14 тонн</td>
        </tr>
                    <tr>
            <td>Подьем рядом с платформой</td>
            <td>4 тонны</td>
        </tr>
                    <tr>
            <td>Выдвижение стрелы</td>
            <td>10 метров</td>
        </tr>
        <tr>
            <td>Подьем на мах вылете</td>
            <td>1 тонна</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                                        <tr>
            <td>смена </td>
            <td>от 12 500 р</td>
        </tr>
                                                        <tr>
            <td>далее 10 км от МКАД	</td>
            <td>+60 р за 1 км</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Манипулятор MAN TGL 12/180 4x2"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>MAN TGL 12/180 4x2</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
        <table width="100%">
                    <tr>
            <td>Полная масса</td>
            <td>12 тонн</td>
        </tr>
                    <tr>
            <td>Мощность двигателя	</td>
            <td>180 л.с.</td>
        </tr>
                    <tr>
            <td>КПП</td>
            <td>механическая</td>
        </tr>
                    <tr>
            <td>Экологический класс	</td>
            <td>ЕВРО 5</td>
        </tr>
                    <tr>
            <td>Масса в снаряенном состоянии</td>
            <td>7 тонн</td>
        </tr>
                    <tr>
            <td>Возможная масса груза</td>
            <td>5 тонн</td>
        </tr>
                    <tr>
            <td>Манипулятор</td>
            <td>MKG 142T</td>
        </tr>
                    <tr>
            <td>Грузовой момент</td>
            <td>14 тонн</td>
        </tr>
                    <tr>
            <td>Подьем рядом с платформой</td>
            <td>4 тонны</td>
        </tr>
                    <tr>
            <td>Выдвижение стрелы</td>
            <td>10 метров</td>
        </tr>
                    <tr>
            <td>Подьем на мах вылете</td>
            <td>1 тонна</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                                        <tr>
            <td>смена </td>
            <td>от 12 500 р</td>
        </tr>
                                                        <tr>
            <td>далее 10 км от МКАД	</td>
            <td>+60 р за 1 км</td>
        </tr>
                            </table>
        </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image manmain' src={man2} alt='аренда MAN TGL 12/180 4x2'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Manipulator2;