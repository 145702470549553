import React from "react"
import Header from "./components/Header"
import Home from "./pages/Home"
import { BrowserRouter } from "react-router-dom"
import{ Routes, Route, Navigate} from "react-router-dom";
import About from "./pages/aboutPage"
import Article from "./pages/articlesPage"
import Contact from "./pages/contactsPage"
import MainKompressori from "./pages/kompressoriMain"
import MainGeneratory from "./pages/generatoryMain"
import MainManipulatori from "./pages/manipulatoriMain"
import MainPodemniki from "./pages/podemnikiMain"
import MainSellTech from "./pages/selltechMain"
import Footer from "./components/footer"
import Contactsmini from "./components/contactsmini"
import PrivacyPolitic from "./pages/privacypolitic"
import QuestionsAnswers from "./pages/QandA"

import Article1 from "./pages/subsarticles/Article1"
import Article2 from "./pages/subsarticles/Article2"
import Article3 from "./pages/subsarticles/Article3"
import Article4 from "./pages/subsarticles/Article4"
import Article5 from "./pages/subsarticles/Article5"
import Article6 from "./pages/subsarticles/Article6"
import Article7 from "./pages/subsarticles/Article7"
import Article8 from "./pages/subsarticles/Article8"
import Article9 from "./pages/subsarticles/Article9"
import Article10 from "./pages/subsarticles/Article10"
import Article11 from "./pages/subsarticles/Article11"
import Article12 from "./pages/subsarticles/Article12"
import Article13 from "./pages/subsarticles/Article13"
import Article14 from "./pages/subsarticles/Article14"
import Article15 from "./pages/subsarticles/Article15"
import Article16 from "./pages/subsarticles/Article16"

import Generator1 from "./pages/generatory/generator1"
import Generator2 from "./pages/generatory/generator2"
import Generator3 from "./pages/generatory/generator3"
import Generator4 from "./pages/generatory/generator4"
import Generator5 from "./pages/generatory/generator5"
import Generator6 from "./pages/generatory/generator6"
import Generator7 from "./pages/generatory/generator7"
import Generator8 from "./pages/generatory/generator8"
import Generator9 from "./pages/generatory/generator9"
import Generator10 from "./pages/generatory/generator10"
import Generator11 from "./pages/generatory/generator11"
import Generator12 from "./pages/generatory/generator12"
import Kompressor1 from "./pages/kompressory/kompressor1"
import Kompressor2 from "./pages/kompressory/kompressor2"
import Kompressor3 from "./pages/kompressory/kompressor3"
import Kompressor4 from "./pages/kompressory/kompressor4"
import Kompressor5 from "./pages/kompressory/kompressor5"
import Kompressor6 from "./pages/kompressory/kompressor6"
import Kompressor7 from "./pages/kompressory/kompressor7"
import Kompressor8 from "./pages/kompressory/kompressor8"
import Podemnik1 from "./pages/podemniky/podemnik1"
import Podemnik2 from "./pages/podemniky/podemnik2"
import Podemnik3 from "./pages/podemniky/podemnik3"


import Manipulator1 from "./pages/manipilatory/manipulator1"
import Manipulator2 from "./pages/manipilatory/manipulator2"
import Selltech1 from "./pages/selltech/selltech1"

import Helmet from "react-helmet"
function App() {
  
  
    return(
      <BrowserRouter>
      <Helmet title="Аренда генераторов и компрессоров">
                <title>Аренда генераторов и компрессоров</title>
                <meta name="description" content="«Машины и механизмы» предлагает в аренду дизельные компрессоры, дизельные генераторы, и вышки на срок от 5 суток. У нас всегда выгодные расценки и полная техническая поддержка."/>
                <html lang="ru"></html>
                
      </Helmet>
      <Header/>
        <div className="appdiv">
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/articles" element={<Article/>}/>

            {/* статьи */}
            <Route path="/404" element={<Selltech1/>}/>
            <Route path="*" element={<Navigate to="/404" replace />} />

            <Route path="/articles/Article1" element={<Article1/>}/>
            <Route path="/articles/Article2" element={<Article2/>}/>
            <Route path="/articles/Article3" element={<Article3/>}/>
            <Route path="/articles/Article4" element={<Article4/>}/>
            <Route path="/articles/Article5" element={<Article5/>}/>
            <Route path="/articles/Article6" element={<Article6/>}/>
            <Route path="/articles/Article7" element={<Article7/>}/>
            <Route path="/articles/Article8" element={<Article8/>}/>
            <Route path="/articles/Article9" element={<Article9/>}/>
            <Route path="/articles/Article10" element={<Article10/>}/>
            <Route path="/articles/Article11" element={<Article11/>}/>
            <Route path="/articles/Article12" element={<Article12/>}/>
            <Route path="/articles/Article13" element={<Article13/>}/>
            <Route path="/articles/Article14" element={<Article14/>}/>
            <Route path="/articles/Article15" element={<Article15/>}/>
            <Route path="/articles/Article16" element={<Article16/>}/>

            {/*Генераторы*/}
            <Route path="/generators/AirmanSDG25S" element={<Generator1/>}/>
            <Route path="/generators/DenyoDCA35" element={<Generator2/>}/>
            <Route path="/generators/DenyoDCA45" element={<Generator3/>}/>
            <Route path="/generators/AirmanSDG60S" element={<Generator4/>}/>
            <Route path="/generators/DenyoDCA75" element={<Generator5/>}/>
            <Route path="/generators/AirmanSDG100S" element={<Generator6/>}/>
            <Route path="/generators/AirmanSDG150S" element={<Generator7/>}/>
            <Route path="/generators/AirmanSDG220S" element={<Generator8/>}/>
            <Route path="/generators/AirmanSDG300S" element={<Generator9/>}/>
            <Route path="/generators/Wilson380E" element={<Generator10/>}/>
            <Route path="/generators/WILSON700E" element={<Generator11/>}/>
            <Route path="/generators/CumminsC500D5" element={<Generator12/>}/>

            {/*Компрессоры*/}
            <Route path="/disel-kompressors/AirmanPDS185SC" element={<Kompressor1/>}/>
            <Route path="/disel-kompressors/AirmanPDS265SC" element={<Kompressor2/>}/>
            <Route path="/disel-kompressors/AirmanPDS390SC" element={<Kompressor3/>}/>
            <Route path="/disel-kompressors/AirmanPDS655S" element={<Kompressor4/>}/>
            <Route path="/disel-kompressors/AirmanPDS400SAC" element={<Kompressor5/>}/>
            <Route path="/disel-kompressors/Airman530S" element={<Kompressor6/>}/>
            <Route path="/disel-kompressors/AirmanPDSF330DPC" element={<Kompressor7/>}/>
            <Route path="/disel-kompressors/AirmanPDSF920S" element={<Kompressor8/>}/>

            {/*Электрические компрессоры*/}
            <Route path="/elek-kompressors/AirmanSMS22RD" element={<Podemnik1/>}/>
            <Route path="/elek-kompressors/AirmanSMS37RD" element={<Podemnik2/>}/>
            <Route path="/elek-kompressors/AirmanSMS75RD" element={<Podemnik3/>}/>

            {/*Манипуляторы*/}
            <Route path="/manipulators/MercedesBenzAtego1218" element={<Manipulator1/>}/>
            <Route path="/manipulators/MANTGL" element={<Manipulator2/>}/>
            {/*Продажа Техники*/}
            {/* <Route path="/used-maschinery1" element={<Selltech1/>}/> */}
            <Route path="/contacts" element={<Contact/>}/>
            <Route path="/generators" element={<MainGeneratory/>}/>
            <Route path="/disel-kompressors" element={<MainKompressori/>}/>
            <Route path="/manipulators" element={<MainManipulatori/>}/>
            <Route path="/elek-kompressors" element={<MainPodemniki/>}/>
            {/* <Route path="/used-maschinery" element={<MainSellTech/>}/> */}
            <Route path="/PrivacyPolitic" element={<PrivacyPolitic/>}/>
            <Route path="/FAQ" element={<QuestionsAnswers/>}/>
          </Routes>
        </div>
        <Contactsmini/>
        <Footer/>

      </BrowserRouter>

    )
  
}

export default App