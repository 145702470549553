import React from "react"
import {Link} from "react-router-dom";



class Catalog extends React.Component{
        handleButtonClick = () => {
            window.scrollTo(0, 0);
        }
        render(){ 
            
            return(
                <div className="catalog__container">
                    <ul className="catalog__list">
                        <button className="catalog__list-item" onClick={this.handleButtonClick}><Link to='/disel-kompressors' className="catalog__list-item-link">Дизельные компрессоры</Link></button>
                        
                        <button className="catalog__list-item" onClick={this.handleButtonClick}><Link to='/generators' className="catalog__list-item-link">Генераторы</Link></button>
                        <button className="catalog__list-item" onClick={this.handleButtonClick}><Link to='/elek-kompressors' className="catalog__list-item-link" >Электрические компрессоры</Link></button>
                        <button className="catalog__list-item" onClick={this.handleButtonClick}><Link to='/manipulators' className="catalog__list-item-link">Манипуляторы</Link></button>
                        
                    </ul>
                </div>
            )

        }


}

export default Catalog