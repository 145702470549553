import React from 'react';
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';

const Article =()=>{
   
        
        return(
            <>
            <Helmet title="Машины и Механизмы - статьи"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className='Articles__wrapper'>
                    <div className='Articles__tittle'>Статьи</div>
                    <div className='Articles__container'>
            {/* 1 */}
                        <Link to="/articles/Article1" className='Articles_link'> 
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>27.05.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генераторов: сфера применения</p>
                                <p className='Articles__link-item-text'>Современная жизнь повсеместно замкнута на электричестве. Без него не денешься ни куда — вряд ли можно сейчас встретить человека, который мог</p>
                            </div>
                        </Link>
            {/* 2 */}
                        <Link to="/articles/Article2" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>25.05.2020</p>
                                <p className='Articles__link-item-tittle'>Дизельный двигатель для генератора</p>
                                <p className='Articles__link-item-text'>Двигатель внутреннего сгорания, воспламенение топливной смеси в котором происходит под действием сжатия был изобретён Рудольфом Дизелем и назван в его честь. Главным</p>
                            </div>
                        </Link>
            {/* 3 */}
                        <Link to="/articles/Article3" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>12.05.2020</p>
                                <p className='Articles__link-item-tittle'>Автономные дизельные генераторы</p>
                                <p className='Articles__link-item-text'>Автономный дизельный генератор является основной «рабочей лошадкой» там, где по каким-либо причинам недоступно централизованное электроснабжение,</p>
                            </div>
                        </Link>
            {/* 4 */}
                        <Link to="/articles/Article4" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>04.05.2020</p>
                                <p className='Articles__link-item-tittle'>Как правильно выбрать дизельный генератор для загородного дома</p>
                                <p className='Articles__link-item-text'>Задача любой статьи — дать объективную информацию, и эта статья не исключение, её главная задача — рассказать о том, какие предложения будут ожидать вас на рынке</p>
                            </div>
                        </Link>
            {/* 5 */}
                        <Link to="/articles/Article5" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>24.04.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда электростанций с воздушным охлаждением, рекомендации по эксплуатации</p>
                                <p className='Articles__link-item-text'>Аренда дизельных электростанций накладывает ряд обязательств, в их числе - необходимость внимательно следить за его техническим состоянием и соблюдать общие рекомендации по эксплуатации.</p>
                            </div>
                        </Link>
            {/* 6 */}
                        <Link to="/articles/Article6" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>17.04.2020</p>
                                <p className='Articles__link-item-tittle'>Как правильно выбрать дизельный компрессор?</p>
                                <p className='Articles__link-item-text'>Для чего нужна аренда компрессора? Это выгодно и удобно. Если Вам требуется выполнять разовые или периодические работы,</p>
                            </div>
                        </Link>
            {/* 7 */}
                        <Link to="/articles/Article7" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>09.04.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генератора - как выбирать нужную установку?</p>
                                <p className='Articles__link-item-text'>У Вас возникла необходимость в аренде генератора? Что же, мы можем Вам помочь! Но для начала, Вам необходимо чётко обозначит область будущего применения оборудования</p>
                            </div>
                        </Link>
            {/* 8 */}
                        <Link to="/articles/Article8" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>02.04.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда дизельного компрессора AIRMAN</p>
                                <p className='Articles__link-item-text'>Наша компания имеет два основных вида деятельности: Аренда дизельных генераторов Аренда дизельных компрессоров</p>
                            </div>
                        </Link>
            {/* 9 */}
                        <Link to="/articles/Article9" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>27.03.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда дизельных генераторов DENYO и AIRMAN</p>
                                <p className='Articles__link-item-text'>Наша компания имеет два основных вида деятельности: Аренда генераторов Аренда дизельных компрессоров</p>
                            </div>
                        </Link>
            {/* 10 */}
                        <Link to="/articles/articles/Article10" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>25.03.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генератора – помощь на производстве</p>
                                <p className='Articles__link-item-text'>Гарантией успешной работы любой фирмы сегодня является бесперебойный процесс производства, но препятствовать ему может простой обрыв электропроводки или полное отсутствие электроснабжения</p>
                            </div>
                        </Link>
            {/* 11 */}
                        <Link to="/articles/Article11" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>17.03.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генератора без лишних хлопот</p>
                                <p className='Articles__link-item-text'>Без электричества не возможно представить себе жизнь современного города, отключение электроэнергии даже на короткий не значительный промежуток времени</p>
                            </div>
                        </Link>
            {/* 12 */}
                        <Link to="/articles/Article12" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>08.03.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда дизельных электростанций</p>
                                <p className='Articles__link-item-text'>Ни для кого не секрет, что наиболее эффективным источником подачи электроэнергии в зоне с нестабильным или отсутствующим электрообеспечением являются</p>
                            </div>
                        </Link>
            {/* 13 */}
                        <Link to="/articles/Article13" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>02.03.2020</p>
                                <p className='Articles__link-item-tittle'>Дизельгенераторы — это наша работа!</p>
                                <p className='Articles__link-item-text'>Аренда дизельных генераторов — основное направление деятельности нашей компании. Мы предлагаем нашим клиентам дизельгенераторы от ведущих мировых производителей,</p>
                            </div>
                        </Link>
            {/* 14 */}
                        <Link to="/articles/Article14" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>26.02.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генераторов для бизнеса</p>
                                <p className='Articles__link-item-text'>Аренда генераторов сегодня используется в большом количестве различных сфер деятельности, в тех случаях, когда требуется бесперебойная подача энергии</p>
                            </div>
                        </Link>
            {/* 15 */}
                        <Link to="/articles/Article15" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>11.02.2020</p>
                                <p className='Articles__link-item-tittle'>Выбор генератора</p>
                                <p className='Articles__link-item-text'>Аренда генераторов — занятие не простое и гораздо более серьёзное нежели покупка, например, канцелярских принадлежностей. Для того чтобы правильно выбрать нужный генератор,</p>
                            </div>
                        </Link>
            {/* 16 */}
                        <Link to="/articles/Article16" className='Articles_link'>
                            <div className='Articles__link-item'>
                                <p className='Articles__link-item-date'>08.02.2020</p>
                                <p className='Articles__link-item-tittle'>Аренда генераторов — короткий путь к решению ваших проблем</p>
                                <p className='Articles__link-item-text'>Аренда дизельных генераторов для обеспечения временного или постоянного энергоснабжения объектов без подключения к сетям</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
        )
        
    
    
}

export default Article
