import React from "react"
import SubGeneratori from "./helpcomponents/generatorysub"
import SubKompressori from "./helpcomponents/kompresorisub"

import "../css/slick.css"
import "../css/slick-theme.css"


class Mainpagecatalog extends React.Component{

    constructor(props){
        super(props)

        this.state={
            MPcontent: <SubGeneratori/>,
            MPClass1: "MPcatalog__button MPactive",
            MPClass2: "MPcatalog__button",
        } 
        this.buttonClick1=this.buttonClick1.bind(this)
        this.buttonClick2=this.buttonClick2.bind(this)
    }
    render(){
        return(
            <div className="MPcatalog">
                <div className="MPcatalog__tab">
                    <ul className="MPcatalog__tab__list">
                        <li className="MPcatalog__tab__list-item">
                            <button className={this.state.MPClass1} onClick={this.buttonClick1}>Генераторы</button>
                        </li>
                        <li className="MPcatalog__tab__list-item">
                            <button className={this.state.MPClass2} onClick={this.buttonClick2}>Компрессоры</button>
                        </li>
                    </ul>
                </div>
                <div>{this.state.MPcontent}</div>
            </div>
        )
    }
        buttonClick1(){
            this.setState({MPcontent:<SubGeneratori/>})
            this.setState({MPClass1:"MPcatalog__button MPactive"})
            this.setState({MPClass2:"MPcatalog__button"})
        }
        buttonClick2(){
            this.setState({MPClass2:"MPcatalog__button MPactive"})
            this.setState({MPClass1:"MPcatalog__button"})
            this.setState({MPcontent:<SubKompressori/>})
        }

}
export default Mainpagecatalog