import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"

import Popup from "../../components/popup1";
import { useState } from "react";
import Moreinfo from "../../components/moreinfo";
import HelpP from '../../components/helpcomponents/helpP';
import pod2 from "../../img/elek-kompressor2.jpg"
import { Helmet } from "react-helmet";
function Podemnik2() {

    const [popupActive, setPopUpActive] = useState(false)
    const [state, setState] = useState({ 
        MPcontent:
        <div className="card__table-wrapper">
        <table width="100%">
        <tr>
            <td>Двигатель</td>
            <td>Двухвинтовой, одноступенчатый</td>
        </tr>
                    <tr>
            <td>Производительность	</td>
            <td>6.9 м³ в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.14 кг/см²</td>
        </tr>
                    <tr>
            <td>Номинальная мощность </td>
            <td>37 кВатт</td>
        </tr>
                    <tr>
            <td>Напряжение</td>
            <td>200/200・220 (400/400・440) </td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1195 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2110×1030×1380</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td> - </td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>3 600 р</td>
        </tr>
                            </table>
        </div>,
        MPClass1: "item__content__top-button buttonActive",
        MPClass2: "item__content__top-button",
    });
    return (
        <>
        <Helmet title="Электрический компрессор Airman SMS37RD"></Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <div className='item__tittle'>Электрический компрессор Airman SMS37RD</div>
            <div className='item__container'>
                <div className='item__content'>
                    <div className='item__content__top'>
                        <button className={state.MPClass1} onClick={()=>{
                            setState({
                                MPcontent:
                                <div className="card__table-wrapper">
                                <table width="100%">
        <tr>
            <td>Двигатель</td>
            <td>Двухвинтовой, одноступенчатый</td>
        </tr>
                    <tr>
            <td>Производительность	</td>
            <td>6.9 м³ в минуту</td>
        </tr>
                    <tr>
            <td>Рабочее давление</td>
            <td>7.14 кг/см²</td>
        </tr>
                    <tr>
            <td>Номинальная мощность </td>
            <td>37 кВатт</td>
        </tr>
                    <tr>
            <td>Напряжение</td>
            <td>200/200・220 (400/400・440) </td>
        </tr>
                    <tr>
            <td>Вес</td>
            <td>1195 кг</td>
        </tr>
                    <tr>
            <td>Габариты</td>
            <td>2110×1030×1380</td>
        </tr>
        </table>
        <h2>Стоимость</h2>
        <table width="100%">
                                            <tr>
            <td>в сутки с НДС</td>
            <td> - </td>
        </tr>
                                                        <tr>
            <td>в сутки без НДС</td>
            <td>3 600 р</td>
        </tr>
                            </table>
                                </div>,
                                MPClass1:"item__content__top-button buttonActive",
                                MPClass2:"item__content__top-button"
                            })
                        }}>Технические характеристики</button>
                        <button className={state.MPClass2} onClick={()=>{
                            setState({
                                MPcontent:<HelpP/>,
                                MPClass2:"item__content__top-button buttonActive",
                                MPClass1:"item__content__top-button"
                            })
                        }}>Оплата и Доставка</button>
                    </div>
                    <div className='item__content__main'>{state.MPcontent}</div><p className='eleckompressor_warning'>Для работы с компрессором нужен кабель необходимой длинны и сечения</p>
                    <button className='item__content__bottom-button' onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                </div>
                <img className='item__image podi' src={pod2} alt='Электрический компрессор Airman SMS37RD'></img>
            </div>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        <Moreinfo/>
        </>

    );
    
}

export default Podemnik2;