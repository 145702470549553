import Video from "../components/video"
import Shortabout from "../components/shortabout"
import Mainpagecatalog from "../components/mainpagecatalog"
import Howwork from "../components/workinfo"
import Feedbackmini from "../components/feedbackmini"
import Aboutus from "../components/aboutus"
import Moreinfo from "../components/moreinfo"
import Partnerslider from "../components/partnerslider"
import React from 'react';
import Catalog from "../components/catalog"
import { Helmet } from "react-helmet"
const Home =()=>{
    return(
        <>
            <Helmet title="Аренда генераторов и компрессоров">
                <title>Аренда генераторов и компрессоров</title>
                <meta name="description" content="«Машины и механизмы» предлагает в аренду дизельные компрессоры, дизельные генераторы, и вышки на срок от 5 суток. У нас всегда выгодные расценки и полная техническая поддержка."/>
                <html lang="ru"></html>
                <meta name="yandex-verification" content="c479714c2a7f420b" />
            </Helmet>
            <Video/>
            <Catalog/>
            <Shortabout/>
            <Mainpagecatalog/>
            <Howwork/>
            <Feedbackmini/>
            <Aboutus/>
            <Moreinfo/>
            <Partnerslider/>
        </>
    )
}
export default Home