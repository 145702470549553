import React from "react"
import Catalog from "../components/catalog"
import HeaderSubSpace from "../components/helpcomponents/headerspace"
import {Link} from "react-router-dom";
import Popup from "../components/popup1";
import { useState } from "react";
import Moreinfo from "../components/moreinfo"
import Manipulator1 from "../img/manipulator1.jpeg"
import Manipulator2 from "../img/manipulator2.jpeg"
import { Helmet } from "react-helmet";
const MainManipulatori =()=>{
    const handleButtonClick = () => {
        window.scrollTo(0, 0);
    }  
        const [popupActive, setPopUpActive] = useState(false)
        
        return(
            <> 
            <Helmet title="Каталог манипуляторов"></Helmet>
                <HeaderSubSpace/>
                <Catalog/>
                <div className="manipulatory__wrapper">
                    <title className="manipulatory__tittle">Аренда Манипуляторов</title>
                    <div className="kompressory__container">
                <div className="gS__container-item2">
                            <div className="gS__item__top1">
                                <Link to='/manipulators/MercedesBenzAtego1218' onClick={handleButtonClick} className="gs__item__top-link">
                                    <img src={Manipulator1} className="gs__item__top-pic1" alt="аренда MERSEDES BENZ ATEGO"></img>
                                </Link>
                            
                            </div> 
                            <div className="gS__item__bottom1">
                                <Link to='/manipulators/MercedesBenzAtego1218' className="gs__item__bottom-tittle"> <p onClick={handleButtonClick}>MERSEDES BENZ ATEGO <br/> 1218</p> </Link>

                                <div className="gs__item__bottom-price">
                                    <div className="gs__item__bottom-price-t">Аренда</div>
                                    <div className="gs__item__bottom-price-c">от 12 500 ₽/сутки</div>
                                </div>

                            </div>                                
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item2">
                            <div className="gS__item__top1">
                                <Link to='/manipulators/MANTGL' onClick={handleButtonClick} className="gs__item__top-link">
                                    <img src={Manipulator2} className="gs__item__top-pic1" alt="аренда MAN TGL 12/180 4x2"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom1">
                                <Link to='/manipulators/MANTGL' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}> MAN TGL 12/180 4x2</p> </Link>

                                <div className="gs__item__bottom-price">
                                    <div className="gs__item__bottom-price-t">Аренда:</div>
                                    <div className="gs__item__bottom-price-c">от 12 500 ₽</div>
                                </div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>
                </div>
                <p className="generatory__text-item">Нередко случается так, что нужно перевезти различные крупногабаритные грузы. В этом деле может помочь аренда крана-манипулятора в Москве. Это приспособление прекрасно подходит для перемещения различных подвижных конструкций, строительных материалов и других грузов большого объёма, которые нельзя транспортировать вручную.</p>
                <p className="generatory__text-tittle">Как легко и просто заказать аренду крана-манипулятора в Москве</p>
                <p className="generatory__text-item">Подобные краны обладают различной грузоподъёмностью и длиной кузова. Нередко именно эти параметры являются самыми важными для транспортировки грузов. Помимо этого, разные модели могут отличаться по своей конструкции.</p>
                <p className="generatory__text-item">На сайте у нас представлены две:</p>
                <ul className="generatory__text-list">
                    <li className="generatory__text-item">MAN с манипулятором 5 тонн, полной массой 12 тонн.</li>
                    <li className="generatory__text-item">Мерседес Бенц с манипулятором 5 тонн, полной массой 12 тонн.</li>
                </ul>
                <p className="generatory__text-item">Специалисты компании проконсультируют по интересующим вас вопросам эксплуатации.</p>
                <p className="generatory__text-tittle">Каким образом можно заказать аренду крана-манипулятора в Москве</p>
                <p className="generatory__text-item">Наша компания предлагает только выгодные условия аренды кранов-манипуляторов в Москве! Звоните, обращайтесь к нам!</p>
                </div>
                
                <Moreinfo/>
                     
                     <Popup  active={popupActive} setActive={setPopUpActive} />      
            </>
           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    
}

export default MainManipulatori