import React from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import Kompressor1 from "../../img/kompressor1.jpg"
import Kompressor2 from "../../img/kompressor2.jpg"
import Kompressor3 from "../../img/kompressor.jpg"
import Kompressor4 from "../../img/kompressor4.jpg"
import Kompressor5 from "../../img/kompressor5.jpg"
import Kompressor6 from "../../img/kompressor6.jpg"
import Kompressor7 from "../../img/kompressor7.jpg"
import Kompressor8 from "../../img/kompressor8.jpg"
import Kompressor9 from "../../img/elek-kompressor1.jpg"
import Kompressor10 from "../../img/elek-kompressor2.jpg"
import Kompressor11 from "../../img/elek-kompressor3.jpg"
import Popup from "../popup1";
import { useState } from "react";
const SubKompressori =()=>{

    const handleButtonClick = () => {
        window.scrollTo(0, 0);;
    }
        const [popupActive, setPopUpActive] = useState(false)
        const settings = {
            className: "center",
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 4,
            swipeToSlide: true,
            responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ],   
            afterChange: function(index) {
              console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
              );
            }
          };
    
        return(
                <div className="gS__wrapper">
                    <Slider {...settings} className="Slider1">
                    <div className="gS__container-item">
                            <div className="gS__item__top">
                                <Link to='/disel-kompressors/AirmanPDS185SC' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor1} className="gs__item__top-pic" alt="аренда AIRMAN PDS 185 SC-W"></img>
                                </Link>
                               
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/disel-kompressors/AirmanPDS185SC' className="gs__item__bottom-tittle"> <p onClick={handleButtonClick}>Дизельный компрессор <br/> AIRMAN PDS 185 SC</p> </Link>
                                <div className="gs__item__bottom-power">5 м³ в минуту</div>
                                <div className="gs__item__bottom-power">7.5 кг/см²</div>

                            </div>                                
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                <Link to='/disel-kompressors/AirmanPDS265SC' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor2} className="gs__item__top-pic" alt="аренда AIRMAN PDS 265 SC"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/disel-kompressors/AirmanPDS265SC' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор  <br/>AIRMAN PDS 265 SC</p> </Link>
                                <div className="gs__item__bottom-power">7.5 м³ в минуту</div>
                                <div className="gs__item__bottom-power">7.5 кг/см²</div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>
                        
                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                <Link to='/disel-kompressors/AirmanPDSF330DPC' className="gs__item__top-link" onClick={handleButtonClick}>
                                    <img src={Kompressor7} className="gs__item__top-pic" alt="аренда AIRMAN PDSF330DPC-W"></img>
                                </Link>
                            </div> 
                            <div className="gS__item__bottom">
                                <Link to='/disel-kompressors/AirmanPDSF330DPC' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор  <br/>AIRMAN PDSF330DPC-W</p> </Link>
                                <div className="gs__item__bottom-power">9.3 м³ в минуту</div>
                                <div className="gs__item__bottom-power">10.3 - 8 кг/см²</div>
                                
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/disel-kompressors/AirmanPDS390SC' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor3} className="gs__item__top-pic" alt="аренда Airman PDS 390 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/disel-kompressors/AirmanPDS390SC' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS 390 SC-W</p></Link>
                                    <div className="gs__item__bottom-power">11 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">7.1 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/disel-kompressors/AirmanPDS655S' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor4} className="gs__item__top-pic" alt="аренда Airman PDS 655 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/disel-kompressors/AirmanPDS655S' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS 655 S</p></Link>
                                    <div className="gs__item__bottom-power">18.5 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">7.1 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/disel-kompressors/AirmanPDS400SAC' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor5} className="gs__item__top-pic" alt="аренда Airman PDS400 S 6B5"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/disel-kompressors/AirmanPDS400SAC' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>Airman PDS400 SAC</p></Link>
                                    <div className="gs__item__bottom-power">11 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">8 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/disel-kompressors/Airman530S' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor6} className="gs__item__top-pic" alt="аренда AIRMAN 530 S"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/disel-kompressors/Airman530S' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/> AIRMAN 530 S</p></Link>
                                    <div className="gs__item__bottom-power">15 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">10.7 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/disel-kompressors/AirmanPDSF920S' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor8} className="gs__item__top-pic" alt="аренда AIRMAN PDSF920S-W"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/disel-kompressors/AirmanPDSF920S' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Дизельный компрессор<br/>AIRMAN PDSF920S-W-AC</p></Link>
                                    <div className="gs__item__bottom-power">26 м³ в минуту</div>
                                    <div className="gs__item__bottom-power">10.3 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                        
                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/elek-kompressors/AirmanSMS22RD' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor9} className="gs__item__top-pic" alt="аренда электрического компрессора AIRMAN SMS22RD"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/elek-kompressors/AirmanSMS22RD' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Электрический <br/> компрессор Airman SMS22RD</p></Link>
                                    <div className="gs__item__bottom-power">4.1 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/elek-kompressors/AirmanSMS37RD' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor10} className="gs__item__top-pic" alt="аренда электрического компрессора AIRMAN SMS37RD"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/elek-kompressors/AirmanSMS37RD' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Электрический <br/> компрессор SMS37RD</p></Link>
                                    <div className="gs__item__bottom-power">6.9 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>

                        <div className="gS__container-item">
                            <div className="gS__item__top">
                                    <Link to='/elek-kompressors/AirmanSMS75RD' className="gs__item__top-link" onClick={handleButtonClick}>
                                        <img src={Kompressor11} className="gs__item__top-pic" alt="аренда электрического компрессора AIRMAN SMS75RD"></img>
                                    </Link>
                                </div> 
                                <div className="gS__item__bottom">
                                    <Link to='/elek-kompressors/AirmanSMS75RD' className="gs__item__bottom-tittle"><p onClick={handleButtonClick}>Электрический <br/> компрессор SMS75RD</p></Link>
                                    <div className="gs__item__bottom-power">13.9 м³ в минуту</div>
                                        <div className="gs__item__bottom-power">7.14 кг/см²</div>
                                    
                            </div>
                            <button className="gs__item__bottom-button" onClick={()=>setPopUpActive(true)}>Заказать звонок</button>                            
                        </div>
                    
                    </Slider>
                    <Popup  active={popupActive} setActive={setPopUpActive} />
                    <div>
                        <Link to='/disel-kompressors' className="gs__bottom__link" onClick={handleButtonClick}>
                            Смотреть все
                        </Link>
                    </div>                    
                </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        )
    }


export default SubKompressori