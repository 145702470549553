import React from 'react';
import Catalog from "../../components/catalog"
import HeaderSubSpace from "../../components/helpcomponents/headerspace"
import Popup from "../../components/popup1";
import { useState } from "react";
import { Helmet } from "react-helmet";

function Selltech1() {
    const [popupActive, setPopUpActive] = useState(false);

    return (
        <>
         <Helmet>
            <title>404: Страница не найдена</title>
            <meta name="robots" content="noindex, follow" />
        </Helmet>
        <HeaderSubSpace/>
        <Catalog/>

        <div className='item__wrapper'>
            <h1 className='item__tittle'>404: Страница не найдена</h1>
            <p>К сожалению, запрашиваемая страница не существует.</p>
        </div>
        <Popup  active={popupActive} setActive={setPopUpActive} />
        </>

    );
}

export default Selltech1;