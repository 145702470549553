import React, { useState } from 'react';
import logo from '../img/logo.png'
import Popup from "./popup1";
import menu from "../img/menu.png"
import cross from "../img/cross.png"
import{ Link} from "react-router-dom";
import Mobilemenu from './mobilemenu';

const Header =()=>{
  const [popupActive, setPopUpActive] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
        const handleButtonClick = () => {
          window.scrollTo(0, 0);
        }
        return(
          
          <header className={menuActive?"header active ":"demenu" }>
            <nav className="header__container">
                <div className="logo ">
                    <Link to='/'><img className="logo-image" src={logo} alt='logo'/></Link>
                    <Link to='/' className="logo__name" >
                        <div className="company-name">МАШИНЫ</div>
                        <div className="company-name"> И МЕХАНИЗМЫ</div>
                    </Link>
                </div>
                <img alt='мобильное меню' className={menuActive?"menu nonactive ":"menu " } src={menu} onClick={ () => {setMenuActive(true)} }></img>
                <img alt='мобильное меню' className={menuActive?"demenu ":"demenu nonactive" } src={cross} onClick={ () => {setMenuActive(false)} }></img>
                <div className="business">Аренда и Продажа Оборудования </div>
                <ul className="menu__list">
                    <button className="menu__item" onClick={handleButtonClick}><Link to="/about" className="menu-link" id="menuLink" >О компании</Link></button>
                    <button className="menu__item" onClick={handleButtonClick}><Link to="/articles" className="menu-link">Статьи</Link></button>
                    <button className="menu__item" onClick={handleButtonClick}><Link to="/contacts" className="menu-link">Контакты / Карты</Link></button>
                </ul>
                <div className="contacts__wrapper">
                    <div className="headerphone" onClick={()=>setPopUpActive(true)}>
                      <a className="phone-number" href="tel:+74957737177" >+7 (495) 773-71-77</a>
                      <a className="phone-number" href="tel:+79257154820" >+7 (925) 715-48-20</a>
                    </div>
                </div>
            </nav>
            <Mobilemenu active={menuActive} setActive={setMenuActive}/>
            <Popup  active={popupActive} setActive={setPopUpActive} />
          </header>

      )
    }
  

export default Header;